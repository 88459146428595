.features {
  margin-top: 88px;
}

.features__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  margin: 0 0 60px;
}

.features-right {
  max-width: 683px;
}

.features-right__image {
  max-width: 683px;
  width: 100%;
}

.features-title {
  font-family: "BodyGrotesqueLarge", sans-serif;
  letter-spacing: -0.25px;
  text-align: start;
  margin-bottom: 16px;
}

/* MainPage.css */
.main-description {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.25px;
  color: rgba(102, 108, 116, 1);
  margin-bottom: 30px;
}

/* Adjust the button layout */
.features-buttons {
  align-items: center;
}

.button-free,
.button-demo {
  height: 56px;
}

/* Style for list items */
.features-list {
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* MainPage.css */
.features-buttons {
  max-width: 448px;
  display: flex;
  margin-bottom: 32px;
}

.button-free,
.button-demo {
  flex: 1; /* Each button takes equal space in the container */
  min-width: 218px; /* Minimum width */
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 16px; /* rounded corners */
  font-family: "Inter", sans-serif; /* font family */
  font-size: 16px; /* font size */
  font-weight: 600; /* font weight */
  line-height: 1.5rem; /* line height */
  letter-spacing: -0.1px; /* letter spacing */
  text-align: center; /* text alignment */
  cursor: pointer; /* cursor shape when hovering */
  border: none; /* no border by default */
}

.button-free {
  background-color: rgba(82, 76, 189, 1); /* button background color */
  color: #ffffff;
  border: 2px solid rgba(82, 76, 189, 1); /* border color and size */
}

.button-demo {
  background-color: rgba(
    255,
    255,
    255,
    1
  ); /* button background color - white */
  color: rgba(82, 76, 189, 1); /* button text color */
  border: 2px solid rgba(82, 76, 189, 1); /* border color and size */
}

/* MainPage.css */
.features-list {
  list-style: none; /* Removes default list styling */
  padding: 0; /* Removes default padding */
}

.feature-item {
  display: flex;
  text-align: left;
  gap: 8px;
  margin-bottom: 8px;
}

.feature-item span {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.25px;
  color: rgba(15, 23, 33, 1);
}

.feature-item img {
  width: 24px; /* Set the width of the check icon */
  height: 24px; /* Set the height of the check icon */
}

@media (min-width: 952px) {
  .features-title {
    max-width: 566px;
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
  }

  .main-description {
    width: 482px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
  .features-buttons {
    flex-direction: row;
    gap: 12px;
  }

  .button-free,
  .button-demo {
    width: 100%; /* Full width for buttons */
  }

  .feature-item span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

@media (max-width: 952px) {
  .features__inner {
    flex-direction: column;
  }

  .features-content {
    max-width: 80%;
    min-width: 310px;
  }

  .features-right {
    width: 80%;
    min-width: 310px;
    height: auto;
  }

  .features-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 42.19px;
  }

  .main-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .features-buttons {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center;
    max-width: 100%;
    gap: 8px;
  }

  .button-free,
  .button-demo {
    width: 100%;
    height: 56px;
  }

  .feature-item span {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 10px; /* Space between items */
  }
}

/* Adjust the title and description for mobile screens */
/* Testimonials */

.testimonials {
  background: #ffffff;
  padding: 40px; /* Adjust the padding as needed */
}

.testimonials-title {
  font-family: "BodyGrotesque", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -0.0025em;
  text-align: center;
  color: rgba(15, 23, 33, 1);
}

.testimonials-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonial {
  margin-bottom: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(245, 245, 245, 1);
  border-radius: 32px;
  padding: 48px;
  box-sizing: border-box;
}

.testimonial-text {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(15, 23, 33, 1);
  margin-top: 0;
}

.testimonial-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 16px; /* Adjust border-radius as needed */
}

.testimonial-avatar {
  width: 64px; /* Adjust size as needed */
  height: 64px; /* Adjust size as needed */
  border-radius: 50%;
  margin-right: 16px; /* Adjust space between avatar and text as needed */
}

.testimonial-text-block {
  display: flex;
  flex-direction: column;
}

.testimonial-author {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: rgba(15, 23, 33, 1);
  margin-bottom: 2px; /* Adjust space between author and role as needed */
}

.testimonial-role {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(102, 108, 116, 1); /* Lighter text color */
}

@media (min-width: 769px) {
  .testimonials-title {
    height: 48px;
    margin-bottom: 70px;
  }
  .testimonials-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .testimonial {
    width: calc(50% - 24px);
    padding: 24px;
  }
}

@media (min-width: 1024px) {
  .testimonial {
    width: calc(33.333% - 24px);
    height: 250px;
  }
}

@media (max-width: 769px) {
  .testimonials-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .testimonial {
    width: 500px;
    margin: 0 auto;
    margin-bottom: 24px;
    padding: 24px;
    box-sizing: border-box;
  }
}

/* Service */
.service-section__inner {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin: 90px 0;
}

.service {
  background-color: rgba(245, 245, 245, 1); /* White background */
  border-radius: 32px; /* Rounded corners on the top-left and bottom-left */
  width: 100%; /* Full width on small screens */
  max-width: 700px; /* Maximum width to control size on larger screens */
  height: auto;
  padding: 16px;
}

.service-image {
  width: 100%; /* Full width of the parent container */
  height: auto; /* Maintain aspect ratio */
}

.service-content {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center;
  max-width: 652px;
  height: auto;
}

.service-title {
  font-family: "BodyGrotesque", sans-serif; /* Assuming you have this font available */
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.0025em;
  text-align: left;
  color: rgba(15, 23, 33, 1);
  font-weight: bold;
  margin-bottom: 8px; /* Space below the title */
}

.service-title-text {
  font-family: "Inter", sans-serif; /* Ensure the 'Inter' font is loaded in your project */
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(102, 108, 116, 1); /* This sets the text color */
}

.service-list {
  list-style: none; /* Remove default list styling */
  padding: 0; /* Remove default padding */
  margin-bottom: 16px; /* Space below the list */
}

.service-list li {
  display: flex;
  align-items: start;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: rgba(15, 23, 33, 1); /* Text color */
  margin-bottom: 10px; /* Space between items */
}

.service-icon {
  margin-right: 8px; /* Space after the icon */
}

.buttons-container {
  gap: 12px;
}

.service-button-in,
.service-button-out {
  display: flex; /* For inline layout with flexibility */
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  padding: 16px 32px;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -0.1px;
  text-align: center;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
  gap: 10px;
  border-radius: 16px;
}

.service-button-in {
  background-color: rgba(82, 76, 189, 1);
  color: #ffffff;
  border: 2px solid rgba(82, 76, 189, 1);
}

.service-button-out {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(82, 76, 189, 1);
  border: 2px solid rgba(82, 76, 189, 1);
}

.button-image-out {
  width: 24px; /* Use rem for scalable image size */
  height: auto; /* Use rem for scalable image size */
}

@media (min-width: 980px) {
  .service-content {
    padding: 32px;
  }

  .service-title {
    font-size: 32px;
  }

  .service-title-text {
    height: 48px;
  }

  .service-list {
    height: 128px;
    padding: 48px 48px 48px 0;
  }

  .service-list li {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }
}

/* Медиазапрос для мобильных устройств */
@media (max-width: 980px) {
  .service-section__inner {
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
  }

  .service {
    max-width: 96%;
  }

  .service-title {
    font-size: 24px;
    line-height: 31.64px;
    margin-top: 20px;
  }

  .service-title-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .service-list {
    margin-top: 32px;
  }

  .service-list li {
    font-size: 15px;
    font-weight: 600;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .service-button-in,
  .service-button-out {
    width: 100%;
  }
}

/* Adjust the flex container if the buttons are next to each other */
.buttons-container {
  display: flex;
  justify-content: start; /* or space-between, depending on layout */
  align-items: center;
  gap: 4px; /* space between buttons */
}

/* Specific styles for buttons */
.service .service-button:first-child {
  background-color: #524cbd; /* Color for 'Start for Free' button */
  color: white;
}

.service .service-button:last-child {
  background-color: #fff; /* Color for 'Learn More' button */
  color: #524cbd;
  border: 2px solid #524cbd; /* Border color */
}

/* ACCESS */

.access__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 24px;
  margin: 90px 0; /* Adjust as needed */
}

.access-content {
  max-width: 448px;
}

.access-visual {
  width: 448px;
}

.Visualphone {
  width: 100%;
}

.access-title {
  position: relative;
  font-family: "BodyGrotesqueFit", sans-serif;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(15, 23, 33, 1);
  margin-bottom: 16px;
}

.access-title .highlight {
  color: rgba(250, 166, 19, 1);
  text-decoration: none;
}

.ellipse-behind {
  position: absolute;
  top: 82%; /* Center vertically */
  left: 25%; /* Start at the horizontal center of the .highlight span */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust the positioning to truly center based on the element's size */
  z-index: -1; /* Places the ellipse behind the text */
  width: 256px;
  height: auto;
}

.access-description {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(102, 108, 116, 1);
}

.access-visual img.star {
  top: 2%; /* Position from the top of the .access-visual container */
  transform: translate(-80%, -50%); /* Adjust the translation if necessary */
  width: 40px; /* Example size, adjust using percentages for responsiveness if needed */
  height: 40px; /* Keep the height same as width for a square aspect ratio */
  z-index: 10; /* Ensures the star is above other elements */
}

@media (min-width: 980px) {
  .access-title {
    line-height: 48px;
  }

  .access-description {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    margin: 16px 0 50px;
  }
}

@media (max-width: 980px) {
  .access__inner {
    flex-direction: column-reverse;
    margin: 40px 0;
  }
  .access-content {
    width: 100%;
  }
  .access-visual {
    width: 100%;
    height: auto;
  }
  .access-title,
  .access-title .highlight {
    font-size: 24px;
    line-height: 31.64px;
  }

  .access-description {
    font-weight: 400;
    font-size: 14px; /* Smaller font size on mobile devices */
    line-height: 20px;
    margin: 8px 0 32px;
  }

  .access-title,
  .access-title .highlight,
  .access-description {
    text-align: center; /* Центрирование текста */
  }

  .ellipse-behind {
    position: absolute;
    top: 80%; /* Center vertically */
    left: 50%; /* Start at the horizontal center of the .highlight span */
    transform: translate(
      -50%,
      -50%
    ); /* Adjust the positioning to truly center based on the element's size */
    z-index: -1;
    max-height: 70%;
    max-width: 60%;
  }
}

@media (min-width: 337px) and (max-width: 440px) {
  .ellipse-behind {
    display: none;
  }
}
/* FAQ */

.faq {
  display: flex;
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  text-align: center; /* Centers text */
  background: #ffffff;
  border-radius: 10px;
}

.faq__inner {
  gap: 24px;
  margin: 0 0 60px; /* Adjust as needed */
}

.faq-title {
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "BodyGrotesque", sans-serif;
  letter-spacing: -0.0025em;
  color: rgba(15, 23, 33, 1); /* Assuming you want to set text color */
}

.faq-container {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center;
  max-width: 920px;
}

.faq-item {
  width: 100%;
  padding: 25px;
  margin: 10px 0; /* Set margin around the item */
  border-radius: 8px; /* Rounded corners */
  background-color: rgba(245, 245, 245, 1); /* White background */
  box-sizing: border-box;
  color: rgba(15, 23, 33, 1);
}

.faq-question {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  letter-spacing: -0.25px; /* Adjust letter spacing as needed */
  text-align: left; /* Align text to the left */
  color: rgba(15, 23, 33, 1); /* Dark text color for better readability */
  display: flex; /* Flex display to align icon and text */
  justify-content: space-between; /* Space out the question and the icon */
  align-items: center; /* Vertically align items in the middle */
  cursor: pointer; /* Change cursor to pointer to indicate clickable area */
}

.faq-icon {
  width: 24px;
  height: 24px;
}

.faq-answer {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, padding 0.3s ease-out,
    box-shadow 0.3s ease-out;
}

.faq-answer.show {
  max-height: 1000px; /* Maximum height when expanded */
  overflow: visible;
  padding-top: 24px;
  color: rgba(102, 108, 116, 1);
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
}

/* Content styles */
.faq-answer-content {
  color: rgba(102, 108, 116, 1); /* The color for the answer text */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
}

@media (min-width: 980px) {
  .faq-title {
    margin-bottom: 20px;
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
  }

  .faq-question {
    font-size: 18px; /* Set the font size */
    line-height: 24px; /* Line height for readability */
  }
}

@media (max-width: 980px) {
  .faq__inner {
    gap: 24px;
    margin: 40px 0; /* Adjust as needed */
  }

  .faq-title {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.64px;
  }

  .faq-question {
    font-size: 14px; /* Set the font size */
    line-height: 20px; /* Line height for readability */
  }
}
/* MediaSection */
.media__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 90px 0;
}

.media-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 920px; /* Fixed width */
  height: auto;
  border-radius: 32px; /* Rounded corners */
  background-color: rgba(234, 234, 247, 1); /* Light purple background */
  border: 2px solid rgba(234, 234, 247, 1);
  gap: 48px;
  box-sizing: border-box;
  overflow: hidden;
}

.media-info {
  display: flex;
  align-items: center; /* Center content vertically within the media info */
  justify-content: space-between; /* Space out children evenly */
  width: 100%; /* Take up full width of parent */
  height: 100%; /* Take up full height of parent */
}

.media-article h2 {
  font-family: "BodyGrotesque", sans-serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: -0.0025em;
  color: rgba(15, 23, 33, 1);
}

.media-article p {
  font-family: "Inter", sans-serif;
  letter-spacing: -0.25px;
  color: rgba(102, 108, 116, 1);
}

.media-link {
  display: inline-flex; /* Aligns the text and icon inline */
  align-items: center; /* Vertically centers the inline content */
  justify-content: center; /* Horizontally centers the content */
  gap: 4px; /* Space between the text and the icon */
  width: 180px; /* Fixed width */
  height: 48px; /* Fixed height */
  border-radius: 16px; /* Rounded corners */
  background-color: rgba(82, 76, 189, 1); /* Purple background */
  text-decoration: none;
  color: white;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
}

.media-link:hover {
  background-color: rgba(62, 56, 169, 1); /* Slightly darker purple on hover */
}

@media (min-width: 768px) {
  .media-article h2 {
    font-size: 32px;
    line-height: 40px;
    margin: 0px 0px 10px;
  }

  .media-article p {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px 0px 40px;
  }

  .media-avatar {
    position: absolute;
    right: -47.75px;
    top: -34px;
  }

  .media-avatar img {
    max-width: 378px;
  }

  .media-section {
    padding: 24px 32px;
  }
}

/* Add responsiveness if the section needs to stack on smaller screens */
@media (max-width: 768px) {
  .media-section {
    flex-direction: column;
    max-width: 702px;
    padding: 16px; /* Уменьшить размер внутренних отступов */
    border-radius: 24px; /* Скругление углов для мобильных устройств */
    gap: 24px; /* Уменьшить расстояние между элементами */
  }

  .media__inner {
    margin: 40px 0;
  }

  .media-info {
    flex-direction: column; /* Space out children evenly */
    width: 100%; /* Take up full width of parent */
    height: auto; /* Take up full height of parent */
    text-align: center;
  }

  .media-article h2 {
    font-size: 24px;
    line-height: 31.64px;
    margin: 0 0 4px;
  }

  .media-article p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0px 0px 32px;
  }

  .media-article,
  .media-link {
    /* изменяем ширину на 100% чтобы элементы занимали всю доступную ширину */
    width: 100%;
    text-align: center; /* текст выравнивается по центру */
  }

  .media-avatar {
    order: -1;
    margin-bottom: 20px; /* добавляем отступ снизу изображения */
  }

  .media-avatar img {
    max-width: 220px;
  }
}

/* MainPage.css */

.qr {
  position: relative;
  max-width: 1392px;
  padding: 0 24px;
  margin: 0 auto;
  width: 100%;
}

.qr-info {
  position: fixed; /* Keep the QR code fixed on the screen */
  bottom: 24px; /* Position from the bottom */
}

.qr-section {
  text-align: center;
  padding: 24px;
}

.qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center content vertically */
  align-items: center;
  max-width: 312px; /* Width as a percentage of the screen width */
  max-height: 388px; /* Height adjusted to content */
  background-color: rgba(
    63,
    132,
    229,
    1
  ); /* Background color as per the image */
  border-radius: 32px; /* Rounded corners as specified */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow for styling */
  z-index: 10; /* High z-index to stay above other content */
  color: #ffffff; /* Text color white */
}

.close-qr {
  cursor: pointer; /* Change cursor to pointer to indicate clickable */
  background: none;
  border: none;
  font-size: 24px; /* Set font size for the close button */
  color: #ffffff; /* Set the color to white */
  position: absolute;
  right: 24px; /* Position from the right */
  top: 24px; /* Position from the top */
}

.qr-code-text {
  font-family: "BodyGrotesque", sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.0025em;
  text-align: center; /* Center the text */
}

.qr-code-subtext {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: center; /* Center the text */
  margin: 8px 0 0; /* Spacing for aesthetic */
}

.qr-code-image {
  max-width: 264px;
  height: auto; /* Height adjusted to the image aspect ratio */
  margin-top: 24px; /* Spacing above the image */
  margin-right: 12px;
}

.qr-collapsed {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 254px;
  max-height: 56px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08);
  /* Добавьте стили для текста и изображения внутри */
}

.qrcode-image {
  width: 24px;
  height: 24px;
}

.qrcode-text {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: rgba(15, 23, 33, 1);
  margin: 0; /* Это уберет стандартные отступы у параграфа */
}

.mobile-download-buttons {
  display: none;
}

@media (max-width: 980px) {
  .qr-wrapper {
    width: 100%;
  }

  .qr-info {
    width: calc(100% - 48px);
    bottom: 14px;
  }

  .qr-container {
    display: none;
  }

  .mobile-download-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative; /* For the close button to be absolutely positioned */
    background-color: rgba(63, 132, 229, 1);
    border-radius: 16px; /* Rounded corners for the container */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow for a slight depth effect */
  }

  .close-qr {
    background: none;
    border: none;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px; /* Size of the close button */
  }

  .download-text {
    font-family: "BodyGrotesqueFit", sans-serif;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.0025em;
    margin-bottom: 24px;
    color: rgba(255, 255, 255, 1); /* Text color */
  }

  .download-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    height: 48px;
    background-color: #ffffff; /* Button background color */
    color: rgba(15, 23, 33, 1); /* Button text color */
    text-decoration: none;
    border: none;
    border-radius: 16px; /* Rounded corners for the buttons */
    padding: 10px 20px;
    margin: 0 0 8px; /* Space between buttons */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional shadow for the buttons */
    font-size: 16px; /* Text size */
  }

  .download-button p {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.1px;
    text-align: center;
  }

  .download-button img {
    margin-left: 4px; /* Space between icon and text */
    width: 24px; /* Icon size */
  }

  .qr-collapsed {
    width: 24px;
    height: 24px;
    border-radius: 16px;
  }

  .qrcode-text {
    display: none;
  }
}

/* Partners */

.partners-section {
  margin: 100px 0;
}

.partners__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 100px 0; /* Adjust as needed */
}

@media (max-width: 980px) {
  .partners__inner {
    flex-direction: column-reverse;
    margin: 40px 0;
  }
}

/* MainPage.css */

.marquee {
  overflow: hidden;
  white-space: nowrap;
}

.marquee-content,
.marquee-content-rest {
  display: inline-flex;
  align-items: center;
  animation: slide 80s linear infinite;
}

.marquee-content {
  animation: slide 80s linear infinite;
}

.marquee-content-rest {
  animation: slide 30s linear infinite;
}

.marquee-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  height: auto;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.img_company {
  width: 100px;
  height: 100px;
}

.company-name {
  margin-top: 16px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.0025em;
  text-align: center;
}

@media (min-width: 780px) {
  .company_name {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .marquee-item {
    max-width: 250px;
    width: 100%;
  }
}

/* Ensure responsiveness and alignment */
@media (max-width: 780px) {
  .marquee-item {
    width: 180px;
    justify-content: flex-start;
  }

  .img_company {
    max-width: 56px; /* Smaller size on mobile */
    max-height: 56px; /* Smaller size on mobile */
  }

  .name_company {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }
}
