/* 1ST SECTION */
/* MAIN */
:root {
  --background-image: url('../../../Picture/Banner-2.png');
}

.suplier {
  margin-top: 88px;
}

.suppliers-pages {
  margin-bottom: 80px;
}

.hero-section {
  display: flex;
  align-items: center;
  justify-content: start;
  background-size: cover;
  background-position: center;
  color: white;
  border-radius: 32px;
  background-image: var(--background-image);
}

.hero-content {
  text-align: center;
  color: white;
  max-width: 600px;
}

.hero-content .hero-title {
  font-family: "BodyGrotesqueLarge", sans-serif;
  color: white;
}

.custom-paragraph {
  font-family: "Inter", sans-serif;
}

.hero-buttons {
  display: flex;
  gap: 15px;
}

.btn-secondary {
  background-color: transparent; /* Transparent or a secondary color */
  color: white;
  border: 1px solid white;
}

.main-section {
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 50px 20px;
  color: #fff;
}

.main-content {
  max-width: 800px; /* Fixed width */
  height: auto;
  text-align: left;
  margin: 200px 40px 0;
}

.main-content h1 {
  font-family: "BodyGrotesque", sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.25px;
  color: rgba(255, 255, 255, 1);
}

.main-content p {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: rgba(255, 255, 255, 1);
  margin-bottom: 16px 0 100px;
}

.main-buttons {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
}

.btn {
  padding: 16px 32px; /* Adjust as needed */
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  border-radius: 16px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, color 0.3s;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.1px;
  flex: none; /* do not grow or shrink */
}

.btn-primary {
  background-color: rgba(255, 255, 255, 1);
  color: rgba(15, 23, 33, 1);
  border: none;
}

.btn-secondary {
  border: 2px solid rgba(255, 255, 255, 1);
  background-color: transparent;
  color: rgba(255, 255, 255, 1);
}

@media (min-width: 769px) {
  :root {
    --background-image: url('../../../Picture/Banner-2.png');
  }

  .hero-section {
    text-align: left;
    padding: 80px;
  }

  .hero-content .hero-title {
    font-size: 56px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -0.25px;
    padding: 0;
  }

  .custom-paragraph {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.25px;
    margin: 40px 0 70px;
  }

  .hero-content .hero-title,
  .custom-paragraph {
    text-align: left;
  }
}

@media (max-width: 769px) {
  :root {
    --background-image: url('../../../Picture/Banner (6).png');
  }
  
  .hero-section {
    justify-content: center;
    padding-top: 40px;
  }
  .hero-content {
    background: none; /* Убираем прозрачный фон для десктопов */
    transform: none;
    justify-content: center;
    align-items: center;
    padding: 16px 16px 224px;
    max-width: 310px;
  }

  .hero-title {
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 31.64px;
    letter-spacing: -0.0025em;
    text-align: center;
    margin-bottom: 6px;
  }

  .custom-paragraph {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .hero-buttons {
    display: inline-flex;
    flex-direction: column; /* Рядовое направление для кнопок */
    gap: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
    max-width: 310px;
    margin-top: 32px;
  }

  .btn {
    width: 90%;
  }
}

/* SAVE TIME SECTION */

.suplier-item span {
  padding-left: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(15, 23, 33, 1); /* Assuming you want the text to be white */
}

.save-time-item .suplier-highlight {
  background: rgba(230, 70, 70, 1); /* Green background for highlighted text */
  color: #ffffff; 
  padding: 0.2em;
}

.save-time-list,
.find-products-list {
  padding: 0;
  list-style: none;
  margin-bottom: 10%;
}

.star-icon {
  margin-right: 8px; /* Adjust the space between the icon and the text */
  width: 18px;
}

.save-time-button,
.find-products-button {
  width: 218px; /* Fixed width */
  height: 56px; /* Fixed height */
  border-radius: 16px; /* Rounded top-left corner */
  border: 2px solid rgba(82, 76, 189, 1); /* Solid border with specified color */
  background: rgba(255, 255, 255, 1); /* White background */
  color: rgba(82, 76, 189, 1); /* Text color matching the border */
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  text-align: center;
}

.save-time-visual,
.find-products-visual {
  position: relative;
}

/* SAVE TIME SECTION */

.ensure-transparency-section,
.save-time-section,
.be-sure-section,
.find-products-section,
.order-section,
.conditions-section,
.integration-section,
.notification-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  margin-bottom: 24px;
}

.save-time__inner {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.find__inner {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.save-time-item,
.suplier-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.save-time-item span {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(15, 23, 33, 1); /* Assuming you want the text to be white */
}

.save-time-item .save-highlight {
  background: rgba(53, 163, 123, 1); /* Green background for highlighted text */
  color: #ffffff; 
  padding: 0.2em;
}

.phone-image {
  width: 100%;
  display: block; /* Makes sure the image doesn't have extra space below */
}

.statuses-container {
  display: flex;
  align-items: start;
  gap: 8px; /* Adjust the gap between the text and the image */
}

.statuses-text {
  margin: 0; /* Removes default margin from the span if present */
  font-size: 16px; /* Example font-size, adjust as needed */
  font-weight: bold; /* Example font-weight, adjust as needed */
  color: #333; /* Example text color, adjust as needed */
}

.statuses-icon {
  max-width: 290px;
  height: auto;
}

@media (min-width: 768px) {

  .save-time-content,
  .find-products-info {
    width: 45%;
  }

  .save-time-visual,
  .find-products-visual {
    width: 48%;
  }

  .find-products-button {
    width: 218px;
    height: 56px;
  }

  .save-time-content,
.find-products-info {
  margin: 48px 0 24px;
}

  .find-products-title,
  .save-time-title {
    font-size: 32px;
    line-height: 40px;
  }

  .find-products-item span,
  .integration-item span,
  .save-time-item span {
    font-size: 18px;
    line-height: 24px;
  }
}

/* 2ND SECTION */

.find-products-title,
.save-time-title {
  font-family: "BodyGrotesque", sans-serif;
  font-weight: 700;
  letter-spacing: -0.0025em;
  text-align: left;
  color: rgba(15, 23, 33, 1);
  margin-bottom: 24px;
}

.integration-item,
.find-products-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.integration-item .suplier-highlight {
  background: rgba(230, 70, 70, 1); /* Green background for highlighted text */
  color: #ffffff; /* White text color for highlighted text */
  padding: 0.2em;
}

.integration-item span {
  margin-right: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(15, 23, 33, 1);
}

/* FIND PRODUCTS */

.find-products-star {
  margin-right: 8px; /* Space between the star icon and the text */
}

.find-products-item .save-highlight {
  background: rgba(53, 163, 123, 1); /* Green background for highlighted text */
  color: #ffffff; /* White text color for highlighted text */
  padding: 0.2em;
}

.find-products-item span {
  margin-left: 8px;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(15, 23, 33, 1);
}

.phone-image {
  width: 100%;
  display: block;
}

.Points-notification {
  width: 100%;
}

@media (max-width: 768px) {
  .save-time__inner {
    flex-direction: column-reverse;
  }

  .find__inner {
    flex-direction: column;
  }

  .find-products-button,
  .save-time-button {
    width: 100%;
  }

  .save-time-list,
  .find-products-list {
    padding: 0;
    list-style: none;
    margin: 25px 0;
  }

  .statuses-container {
    flex-direction: column;
  }

  .find-products-title,
  .save-time-title {
    font-size: 24px;
    line-height: 31.64px;
  }

  .integration-item span,
  .find-products-item span,
  .save-time-item span {
    font-size: 15px;
    line-height: 20px;
  }

  .save-time-content,
  .find-products-info {
    margin: 0 0 24px;
  }

  .statuses-icon {
    width: 100%;
  }
}
