@font-face {
    font-family: "BodyGrotesque";
    src: url("./body-font-family/Body-Grotesque-Bold-trial.woff2") format("woff2"),
        url("./body-font-family/Body-Grotesque-Bold-trial.woff") format("woff");
    font-weight: 700; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: "BodyGrotesqueFit";
    src: url("./body-font-family/Body-Grotesque-Fit-Bold-trial.woff2") format("woff2"),
        url("./body-font-family/Body-Grotesque-Fit-Bold-trial.woff") format("woff");
    font-weight: 700; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: "BodyGrotesqueLarge";
    src: url("./body-font-family/Body-Grotesque-Large-Bold-trial.woff2") format("woff2"),
        url("./body-font-family/Body-Grotesque-Large-Bold-trial.woff") format("woff");
    font-weight: 700; /* Bold */
    font-style: normal;
}

@font-face {
    font-family: "BodyGrotesqueSlim";
    src: url("./body-font-family/Body-Grotesque-Slim-Bold-trial.woff2") format("woff2"),
        url("./body-font-family/Body-Grotesque-Slim-Bold-trial.woff") format("woff");
    font-weight: 700; /* Bold */
    font-style: normal;
}

/* @font-face {
    font-family: 'BodyGrotesque';
    src: url('./body-font-family/Body-Grotesque-Regular-trial.woff2') format('woff2'),
         url('./body-font-family/Body-Grotesque-Regular-trial.woff') format('woff');
    font-weight: 400; 
    font-style: normal;
  } */

/* @font-face {
    font-family: 'BodyGrotesque';
    src: url('./body-font-family/Body-Grotesque-Light-trial.woff2') format('woff2'),
         url('./body-font-family/Body-Grotesque-Light-trial.woff') format('woff');
    font-weight: 300;
    font-style: normal;
  }
   */
/* Add additional @font-face rules for each font variation */

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-Black.woff2") format("woff2"),
        url("./Inter/AnyConv.com__Inter-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-Bold.woff2") format("woff2"), url("./Inter/AnyConv.com__Inter-Bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-ExtraBold.woff2") format("woff2"),
        url("./Inter/AnyConv.com__Inter-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-ExtraLight.woff2") format("woff2"),
        url("./Inter/AnyConv.com__Inter-ExtraLight.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-Light.woff2") format("woff2"),
        url("./Inter/AnyConv.com__Inter-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-Medium.woff2") format("woff2"),
        url("./Inter/AnyConv.com__Inter-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-Regular.woff2") format("woff2"),
        url("./Inter/AnyConv.com__Inter-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-SemiBold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-Thin.woff2") format("woff2"), url("./Inter/AnyConv.com__Inter-Thin.woff") format("woff");
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Inter";
    src: url("./Inter/AnyConv.com__Inter-VariableFont_slnt,wght.woff2") format("woff2");
    font-weight: 100 900;
    font-style: oblique 0deg 10deg;
}

/* Add additional @font-face rules for other font weights and styles as needed */
