.hidden {
    display: none;
}

.navbar {
    position: fixed;
    top: 0;
    width: 100%; /* Fixed positioning should be explicitly defined */
    z-index: 999;
    background-color: #ffffff; /* Background color */
    border-bottom: 1px solid #e6e8ea;
    box-sizing: border-box;
}

.navbar__inner {
    position: relative;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0px;
}

.navbar-left {
    display: flex;
    align-items: center;
}

.navbar-logo {
    height: 40px;
    margin-right: 48px;
}

.navbar-logo img {
    height: 100%;
}

.navbar-links {
    display: flex;
    align-items: center;
}

.nav-link {
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    color: rgba(15, 23, 33, 1); /* Adjust the font color as per design */
    text-align: center;
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-right: 48px;
    position: relative;
    cursor: pointer;
}

.nav-link:last-child {
    margin-right: 0px;
}

.navbar-actions {
    display: flex;
    gap: 10px;
}

.nav-link-active {
    color: rgba(120, 120, 120, 1); /* Dark gray color for active link */
}

/* Ensure that the active link styling overrides the default link styling */
.nav-link.nav-link-active {
    color: rgba(120, 120, 120, 1); /* Dark gray color for active link */
}

/* Dropdown Button */

.contact-dropdown {
    position: relative;
}

.dropbtn {
    background: none;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.dropbtn.active:after {
    transform: rotate(180deg);
    color: rgba(168, 169, 170, 1);
}

/* Dropdown Content */
/* Styles for the dropdown menu */
.dropdown-content {
    display: flex;
    flex-direction: column;
    padding: 14px;
    overflow: hidden; /* For rounded corners */
    position: absolute;
    top: 60px;
    width: 280px; /* Width of the dropdown */
    height: auto; /* Height of the dropdown */
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 16px; /* Top left corner rounded */
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
    gap: 12px; /* Space between items */
    opacity: 1; /* Ensure the dropdown is fully visible */
}

.dropdown-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px; /* Space between the icon and text */
    padding: 5px;
    text-decoration: none;
    color: #000000;
}

.dropdown-item:hover {
    background-color: #f7f7f7; /* Background color on hover */
}

.action-icon:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.action-icon {
    margin-left: 12px; /* Space between the text/email and action icon */
}

/* Styles for the social icons container */
.social-icons {
    display: flex;
    justify-content: center; /* Centers the icons in the dropdown */
    align-items: center; /* Aligns icons vertically */
    gap: 16px; /* Space between icons */
    padding-top: 8px;
    border-top: 1px solid rgba(230, 232, 234, 1);
}

/* Individual social icon link */
.social-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px; /* Width of the icon */
    height: 25px; /* Height of the icon */
    margin-top: 10px;
    padding: 0 24px;
}

.social-link:last-child {
    margin-right: 0; /* Remove right margin for the last icon */
}

.social-link:not(:last-child) {
    border-right: 1px solid rgba(230, 232, 234, 1);
}

.icon {
    display: flex; /* Use flex to center SVG images */
    justify-content: center;
    align-items: center;
    width: 100%; /* SVG will take up the full width of the .icon container */
    height: 100%; /* SVG will take up the full height of the .icon container */
}

.icon img {
    width: 24px; /* Adjust width as needed */
    height: auto; /* Height will scale automatically */
}

/* Icon classes */
.phone-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.email-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.linkedin-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.instagram-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.whatsapp-icon {
    background-repeat: no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
}

.navbar-actions {
    display: flex;
    align-items: center;
    gap: 24px; /* Adjust the gap to match your design */
}

.action-login {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none; /* No background for a link-like button */
    border: none; /* No border unless specified in your design */
    color: #000000; /* Text color - adjust as needed */
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.action-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 4px; /* Space between text and any icons */
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.1px;
    text-align: center;
    color: #ffffff; /* Text color */
    background-color: rgba(82, 76, 189, 1); /* Button background color */
    border: none;
    border-radius: 12px; /* Top left border radius */
    width: 174px; /* Fixed width */
    height: 40px; /* Fixed height */
    cursor: pointer;
    opacity: 1; /* Fully opaque */
}

.action-login.active {
    color: rgba(168, 169, 170, 1);
}
/* Style for the globe/world icon if it is not part of a button */
.world-icon {
    width: 24px; /* Set the width as per your design */
    height: 24px; /* Set the height as per your design */
}

.dropdown-content-login {
    display: flex;
    flex-direction: column;
    overflow: hidden; /* For rounded corners */
    position: absolute;
    background: #ffffff;
    border-radius: 16px;
    overflow: hidden; /* To ensure rounded corners are respected */
    width: 100%;
    border: 1px solid #ddd;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    /* gap: 12px;  */
    opacity: 1;
}

.dropdown-item-login {
    display: flex;
    align-items: center;
    font-family: "Inter", sans-serif;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    padding: 16px;
    border-bottom: 1px solid #eaeaea;
    text-decoration: none;
    color: #000000;
}

.dropdown-item-login:last-child {
    border-bottom: none; /* No border for the last item */
}

.dropdown-item-login:hover {
    background-color: #f8f8f8; /* Light grey background on hover */
}

.icon-login,
.chevron-right {
    margin-right: 12px; /* Space between the icon and the text */
}

.icon-login {
    width: 32px; /* Size of the icon */
    height: auto;
}

.chevron-right {
    margin-left: auto; /* Pushes the chevron to the right */
    width: 24px; /* Size of the chevron */
    height: 24px; /* Size of the chevron */
}

.hamburger-menu {
    display: none;
}

@media (min-width: 1016px) {
    .dropdown-content-login {
        width: 25%;
        top: 60px;
        right: 30px;
    }

    .dropdown-content{
        top: 40px;
        right: -15px;
    }

    .navbar-left {
        margin-right: auto;
    }
}

@media (max-width: 1016px) {
    .navbar-logo {
        height: 32px;
    }

    .navbar.show {
        background-color: white;
        height: 429px;
        border-radius: 0 0 32px 32px;
    }

    .show .navbar__inner {
        padding-bottom: 16px;
    }

    .show .navbar-links {
        position: absolute;
        top: 100%; /* Positioned right below the navbar */
        left: 0; /* Aligned to the left edge of the parent */
        z-index: 999;

        display: block;
        width: 100%; /* Default to full width of the parent container */

        background-color: #ffffff; /* Background color */
        border-radius: 8px;
    }

    .navbar-links.show {
        width: 100%;
        transition: width 0.3s ease-in-out;
    }

    .show .nav-link {
        margin-right: 0;
    }

    .show .nav-link:first-child {
        border-radius: 8px 8px 0 0;
    }

    .show .nav-link:nth-last-of-type(1) {
        border-radius: 0 0 8px 8px;
    }

    .show .nav-link:not(:last-child) {
        padding: 14px 16px;

        color: #000; /* Text color for the items */
        text-align: left; /* Align text to the left */
        line-height: 20px;

        background-color: rgba(245, 245, 245, 1); /* Remove any background color */
        border-bottom: 1px solid #eaeaea; /* Separator between items */
    }

    .show .navbar-link:last-child {
        margin-bottom: 0;
    }
    
    .show .dropdown-content {
        gap: 0;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 999;
        width: 100%;

        padding: 0;
        

        background-color: #ffffff; /* Background color */
        border-radius: 8px;
        border: none;

        box-shadow: none;
    }

    .show .contact-dropdown {
        width: 100%;
        margin-top: 16px;
    }

    .show .dropdown-item {
        padding: 12px 16px;
    }

    .show .dropdown-item {
        color: #000; /* Text color for the items */
        text-align: left; /* Align text to the left */
        line-height: 20px;

        background-color: rgba(245, 245, 245, 1); /* Remove any background color */
        border-bottom: 1px solid #eaeaea; /* Separator between items */
    }

    .show .social-icons {
        padding-top: 0;
        border-top: 0;
        gap: 0;
    }

    .show .social-link {
        margin-top: 0;
        padding: 12px 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: rgba(245, 245, 245, 1); /* Remove any background color */
    }

    .navbar-logo {
        margin-right: 0;
    }

    .hamburger{
        flex-basis: 69px;
    }
    
    /* Стиль для иконки гамбургер-меню */
    .hamburger-menu {
        width: 24px;
        display: block;
        cursor: pointer;
    }

    .hamburger-menu img {
        width: 24px;
        height: auto;
    }

    .action-button {
        display: none;
    }

    .dropbtn {
        display: none;
    }


    .navbar-links {
        display: none;
    }

    /* Стиль для активного состояния гамбургер-меню, когда меню открыто */
    .navbar-links.show {
        width: 100%;
        transition: width 0.3s ease-in-out; /* Smooth transition for width adjustment */
    }

    .dropdown-content-login {
        position: fixed; /* Фиксированное позиционирование относительно вьюпорта */
        left: 0; /* Расположение в левом нижнем углу */
        right: 0; /* Расположение в правом нижнем углу */
        bottom: 0; /* Расположение внизу экрана */
        z-index: 1000;
        background: #fff; /* Пример фона */
        border-top: 1px solid #ccc; /* Пример границы сверху */
        padding: 20px;
        border-radius: 32px 32px 0px 0px;
        box-sizing: border-box;
      }
      
}
