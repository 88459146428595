.footer__inner {
    padding: 48px;
    border-radius: 32px;
    background-color: rgba(31, 31, 31, 1); /* Change as needed */
    display: flex;
    gap: 12px;
    justify-content: space-between;
    position: relative;
    color: #fff;
}

.footer-brand,
.footer-content {
    flex-basis: 50%;
}

.footer-brand {
    max-width: 400px;
}


.footer-bottom_text {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.footer_text_two{
    display: block; /* To ensure the full width is clickable and to position the icons below the email */
    font-family: "Inter", sans-serif; /* Adjust if needed */
    font-size: 8px;
    font-weight: 500;
    line-height: 10px;
    color: #fff;
}


.logo {
    height: 40px;
    display: flex; /* This will align the logo and text side by side */
    align-items: center; /* This will vertically center align the items */
    background-color: rgba(31, 31, 31, 1); /* This matches the black background in your photo */
    color: #fff; /* This is the text color */
}

.logo-image {
    width: 32px;
    height: auto;
    margin-right: 12px; /* Adjust the spacing to match your design */
}

.logo-text {
    margin: 0; /* Removes default margin */
    font-family: "BodyGrotesque", sans-serif;
    font-size: 20px; /* Sets the font size */
    font-weight: 700; /* Sets the weight to bold */
    line-height: 24px; /* Sets the line height */
    letter-spacing: -0.0025em; /* Sets the letter spacing */
    text-align: left; /* Aligns the text to the left */
    color: #fff;
}

.paragraph-style {
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: left;
    margin: 32px 0;
    color: rgba(202, 203, 205, 1);
}

.store-badges {
    display: flex;
    justify-content: start; /* Center horizontally */
    align-items: start;
    flex-wrap: wrap; /* Allow wrapping */
    gap: 12px; /* Space between badges */
}

.store-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    border-radius: 15px; /* Rounded corners */
    background-color: rgba(31, 31, 31, 1); /* Background color */
    border: 2px solid #fff; /* White border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Shadow effect */
    text-decoration: none;
    max-width: 194px;
    max-height: 56px;
    cursor: pointer; /* Changes cursor to pointer on hover */
    transition: all 0.3s ease;
}

.store-badge:hover {
    background-color: rgba(50, 50, 50, 1); /* Slightly lighter background on hover */
    border-color: rgba(255, 255, 255, 0.8); /* Slightly transparent white border on hover */
}

.store-badge img {
    height: 24px;
    width: 24px;
}

/* Text next to the store badge */
.store-badge-text {
    font-weight: bold;
    font-family: "Inter", sans-serif; /* Specify the font-family */
    font-size: 16px; /* Set the font-size */
    font-weight: 600; /* Set the font-weight to semi-bold */
    line-height: 24px; /* Set the line-height */
    letter-spacing: -0.1px; /* Set the letter-spacing */
    color: rgba(255, 255, 255, 1);
    margin-left: 8px;
}

.footer-star {
    position: absolute;
    right: 0;
    left: 97%;
    top: 3%; /* Adjust this value as necessary */
    transform: translateY(-50%); 
}

.footer-star img{
    width: 36px;
    height: auto;
}

.footer-sitemap ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-sitemap ul li a{
    color: #fff;
    text-decoration: none;
}

.footer-contacts .social-links a {
    display: inline-block;
    margin-right: 10px;
}

.footer-content {
    display: flex;
    gap: 100px;
}

/* .footer-sitemap {
  margin-right: 280px;
} */
.footer-sitemap h3 {
    margin: 8px 0 24px;
    font-family: "BodyGrotesque", sans-serif; /* Adjust if needed */
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.0025em;
    text-align: left;
}

.footer-sitemap ul li {
    margin-bottom: 12px; /* Space between list items */
}

.footer-sitemap ul li:last-child {
    margin-bottom: 0; /* No margin for the last item */
}

.footer-sitemap a {
    font-family: "Inter", sans-serif; /* Adjust if needed */
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #ffffff; /* Adjust if needed */
    text-decoration: none; /* Removes underline from links */
}

.footer-sitemap a:hover {
    text-decoration: underline; /* Optional: underline on hover */
}

/* Add more styles as needed */

.footer-contacts {
    color: #ffffff; /* Adjust if needed */
}

.footer-contacts h3 {
    margin: 8px 0 24px;

    font-family: "BodyGrotesque", sans-serif; /* Adjust if needed */
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.0025em;
    text-align: left;
}

.footer-contacts_a {
    display: block; /* To ensure the full width is clickable and to position the icons below the email */
    font-family: "Inter", sans-serif; /* Adjust if needed */
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.25px;
    text-align: left;
    color: #ffffff; /* Adjust if needed */
    margin-bottom: 12px; /* Space between the email and the social icons */
}

.social-links {
    display: flex;
    gap: 12px; /* Space between icons */
}

.social-links img {
    width: 24px; /* Adjust if needed */
    height: 24px; /* Adjust if needed */
}

/* Additional styles for links to remove the default blue color and underline */
.footer-contacts_a,
.social-links a {
    color: inherit; /* Inherits the white color specified above */
    text-decoration: none; /* No underline */
}

/*LANGUAGE*/

.footer-language button {
    background-color: rgba(31, 31, 31, 1); /* Dark background as in the photo */
    color: #fff; /* White text color */
    border: 2px solid #fff; /* White border as indicated */
    border-radius: 12px; /* As specified */
    padding: 8px 16px; /* Top and Bottom - 8px, Left and Right - 16px */
    font-size: 16px; /* Based on typical design, adjust as needed */
    display: inline-flex; /* To align the text and the icon */
    align-items: center; /* Centers items on the cross-axis */
    gap: 4px; /* Space between "Русский" and the chevron icon */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adding some shadow for depth, adjust as needed */
    cursor: pointer; /* To indicate the button is clickable */
    transition: background-color 0.3s ease;
    margin-top: 15px; /* Adjust as needed for positioning */
}

/* Hover effect - Lighten the button color */
.footer-language button:hover {
    background-color: rgba(50, 50, 50, 1); /* Lighter shade when hovered */
}

/* Image icon adjustment if necessary */
.footer-language img {
    /* If the Chevron is an image */
    width: 12px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
    filter: invert(100%); /* Invert color to white if necessary */
}

@media (max-width: 768px) {
    .footer {
        margin-bottom: 20px;
        height: 50%;
    }

    .footer__inner {
        flex-direction: column;
        gap: 24px;
    }

    .store-badges {
        flex-direction: row;
    }

    /* .paragraph-style {
    } */

    .footer-sitemap h3,
    .footer-contacts h3 {
        margin-bottom: 8px;
    }

    .footer-star {
        display: none;
    }
}

@media (max-width: 980px) {
    .footer-bottom_text {
        display: flex;
        flex-direction: column;
    }    
}

/* Responsive styles for mobile */
@media (max-width: 480px) {
    .footer__inner {
        padding: 16px;
    }
    .footer-content {
        flex-direction: column;
        gap: 24px;
    }

    .store-badges {
        flex-direction: column;
        gap: 12px;
        align-items: center;
        justify-content: center;
    }

    .store-badge {
        width: 100%;
    }

    .footer-contacts .social-links {
        align-items: center;
    }

    .footer-language button {
        margin-top: 10px;
    }

    .footer-star {
        position: fixed;
        bottom: 10px;
        right: 10px;
    }
}
