.aboutme__inner {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin: 90px 0 60px;
}

.link-section,
.about-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.about-logo {
  height: 64px; /* Adjust if needed */
}

.about-text-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.25px;
}

.about-text-content {
  font-weight: 500;
  text-align: center;
}

.about-link {
  display: inline-flex;
  gap: 8px;
  align-items: center;
  background-color: rgba(234, 234, 247, 1);
  border-radius: 8px;
  padding: 0px 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.1px;
  text-align: center;
  max-width: 110px; /* Hug width */
  height: 32px; /* Fixed height */
  color: rgba(82, 76, 189, 1); /* Link text color */
  text-decoration: none;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
  margin-left: 8px;
  opacity: 1;
}

.external-link-icon {
  width: 24px;
  height: 24px;
}

/* Описание */
.description-section {
  display: flex;
  justify-content: center;
  margin: 20px 142px 0;
}

.description-section p {
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(102, 108, 116, 1);
  margin-bottom: 24px;
}

/* 3 часть */

.aboutme-title__inner {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin: 80px 0;
}

.values-section {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.value-card {
  background: rgba(245, 245, 245, 1);
  border-radius: 32px;
  padding: 24px;
  max-width: 369px; /* Adjust width as necessary */
  text-align: start;
}

.icon-wrapper {
  border-radius: 16px;
}

.value-icon {
  width: 64px;
  height: 64px;
}

.value-title {
  font-family: "BodyGrotesqueFit";
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.0025em;
  text-align: left;
  color: #333;
  margin: 32px 0 8px;
}

.value-description {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.25px;
  text-align: left;
  color: rgba(102, 108, 116, 1);
  font-size: 16px; /* Adjust font-size as necessary */
}

.value-description .highlighted {
  font-weight: 600;
  color: rgba(102, 108, 116, 1);
  text-decoration-line: underline; /* Добавляет подчеркивание */
  text-decoration-style: solid; /* Стиль линии подчеркивания */
  text-decoration-color: rgba(82, 76, 189, 1); 

}

@media (max-width: 1200px) {
  .aboutme__inner {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin: 90px 0 16px;
  }

  .about-text-content {
    flex-direction: column-reverse;
    gap: 20px;
  }

  .description-section {
    width: 100%;
  }

  .description-section p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .value-title {
    font-family: "BodyGrotesqueFit";
    font-size: 24px;
    font-weight: 700;
    line-height: 31.64px;
    letter-spacing: -0.0025em;
    text-align: left;
    margin: 24px 0 4px;
  }

  .about-text-content,
  .value-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.25px;
    text-align: left;
  }

  .aboutme-title__inner {
    margin-top: 44px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
