/* FormSection.css */

.component-form{
  margin: 0 auto;
  padding: 0;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Скрытие скроллбара для WebKit браузеров */
.modal-content::-webkit-scrollbar {
  display: none;
}

/* Скрытие скроллбара для Firefox и IE */
.modal-content {
  -ms-overflow-style: none; /* IE + Edge */
  scrollbar-width: none; /* Firefox */
}

.modal-content {
  position: relative; /* Для позиционирования крестика и других элементов внутри */
  display: flex;
  flex-direction: column; /* Для вертикального выравнивания содержимого */
  justify-content: space-between;
  max-width: 600px;
  height: 85%;
  border-radius: 24px;
  padding: 24px;
  background: #fff;
  overflow-y: auto;
  z-index: 1001; /* Распределяет контент с начальными и конечными отступами */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
 
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  margin: 20px 0 16px;
}

.modal-title {
  font-family: "Inter", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.0025em;
  text-align: center;
  margin: 0;
}

.close-btn {
  position: absolute;
  right: 24px;
  font-size: 1.5rem;
  cursor: pointer;
  border: none;
  background: transparent;
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  border-radius: 50%; /* Rounded border to make it a circle */
  opacity: 1; /* Fully opaque */
  background-color: rgba(238, 238, 238, 1); /* Light grey background */
}

.form-section {
  display: flex;
  margin: 20px 0 16px;
  padding: 0;
  background-color: rgba(238, 238, 238, 1);
}

.form-tab {
  width: 50%; /* Ширина каждой вкладки */
  height: 32px; /* Высота каждой вкладки */
  padding: 0 12px; /* Внутренние отступы вокруг текста */
  font-family: "Inter", sans-serif;
  background: transparent; /* Начальный фон для неактивной вкладки */
  cursor: pointer; /* Стиль курсора для указания на кликабельность */
  transition: background-color 0.3s;
  border-radius: 8px;
}

.form-tab.active {
  background-color: rgba(82, 76, 189, 1); /* Цвет фона для активной вкладки */
  color: white; /* Цвет текста для активной вкладки */
  opacity: 1;
}

/* Добавьте стили для hover эффекта, если это необходимо */
.form-tab:hover {
  background-color: rgba(82, 76, 189, 1); /* Цвет фона для вкладки при наведении */
  color: white; /* Цвет текста для вкладки при наведении */
  opacity: 0.8; /* Непрозрачность для вкладки при наведении */
}

.section-title {
  margin-bottom: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.25%;
  color: rgba(15, 23, 33, 1);
}

.input-group{
  width: 100%;
}

.input-group input{
  font-family: "Inter", sans-serif;
}

.textarea {
  font-family: "Inter", sans-serif;
  height: 120px;
}

/* Стиль полей ввода при наведении курсора */
input:hover,
textarea:hover {
  font-family: "Inter", sans-serif;
  border-color: #a9a9a9; /* темно-серая граница */
}

/* Стиль полей ввода при фокусе */
input:focus,
textarea:focus {
  font-family: "Inter", sans-serif;
  outline: none;
  font-size: 16px;
  border: 1.5px solid rgba(82, 76, 189, 1);
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* Optional styling */
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

input.error {
  border: 1.5px solid rgba(230, 70, 70, 1); 
}

.error-message {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  background-color: #f44336;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 6px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
  font-size: 12px;
}

input.error + .error-message {
  visibility: visible;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.form-btn {
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.form-btn.cancel {
  background-color: #ccc;
}

.form-btn.submit {
  background-color: #635dff;
  color: white;
}

.select-wrapper {
  position: relative;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.select-wrapper::after {
  content: url("./chevron-down.png");
  position: absolute;
  top: 40%;
  right: 16px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: none; /* Ensures the dropdown arrow doesn't interfere with click events */
}

input,
textarea,
select {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  background-repeat: no-repeat; /* Предотвращает повторение иконки */
  background-position: right 10px center; /* Позиционирование иконки справа и по центру по вертикали */
  background-size: 12px;
  border: 1px solid #ccc; 
  border-radius: 16px;
  margin-bottom: 16px;
  font-size: 16px;
  -webkit-appearance: none; /* Removes default style of select in WebKit browsers */
  -moz-appearance: none; /* Removes default style of select in Firefox */
  appearance: none;
  box-sizing: border-box;
}

select:focus {
  font-family: "Inter", sans-serif;
  border-color: #a9a9a9;
  outline: none;
}

.select-wrapper select:focus,
.select-wrapper select:hover {
  font-family: "Inter", sans-serif;
  outline: none;
  color: #000; /* Darker border on hover/focus */
}

.form-actions {
  font-family: "Inter", sans-serif;
  display: flex; /* Для горизонтального выравнивания кнопок */
  gap: 8px; /* Отступ между кнопками */
}

.form-btn {
  width: 50%; /* Ширина кнопки */
  height: 56px; /* Высота кнопки */
  border-radius: 16px; /* Скругление углов */
  font-family: "Inter", sans-serif; /* Семейство шрифтов */
  font-size: 14px; /* Размер шрифта */
  font-weight: 600; /* Жирность шрифта */
  line-height: 20px; /* Высота строки */
  letter-spacing: -0.1px; /* Межбуквенный интервал */
  text-align: center; /* Выравнивание текста по центру */
  border: none; /* Убрать границу */
  cursor: pointer; /* Курсор в виде руки при наведении */
  transition: 0.3s; /* Плавное изменение фона при наведении */
}

.form-btn.cancel {
  background-color: rgba(238, 238, 238, 1); /* Светло-серый фон */
  color: rgba(15, 23, 33, 1); /* Цвет текста */
  border-radius: 16px; /* Скругление только левых углов */
}

.form-btn.submit {
  background-color: rgba(82, 76, 189, 1); /* Фиолетовый фон */
  color: rgba(255, 255, 255, 1); /* Белый текст */
  border-radius: 16px; /* Скругление всех углов */
}

.form-btn.cancel:hover,
.form-btn.submit:hover {
  background-color: darken(
    rgba(238, 238, 238, 1),
    10%
  ); /* Затемнение фона при наведении */
}

.form-btn.cancel:active,
.form-btn.submit:active {
  transform: translateY(2px); /* Смещение кнопки вниз при клике */
}

.consent-text {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400; /* Makes the text bold */
  line-height: 16px;
  letter-spacing: -0.1px;
  text-align: left;
  margin-top: 16px;
}

.highlight {
  font-weight: 400; /* Makes the text bold */
  text-decoration: underline; /* Underlines the text if needed */
  color: rgba(26, 106, 206, 1);
}

@media (max-width: 648px) {
  .modal-content {
    height: 100%;
  }
}